.categories {
    &__content {
        max-width: 1800px;
    }

    &__info {
        margin-bottom: 20px;
    }

    &__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;

        @media (max-width: $windowSmall) {
            & > div {
                min-width: 100%;
            }
        }

        &__select {
            width: 100%;
            margin-bottom: 15px;
        }

        &__textarea {
            height: 200px !important;
        }

        &__save {
            min-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}