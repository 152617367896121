

.login {
    &__page {
        background-color: $darkBackground;
        width: 100vw;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 90px;
		padding-bottom: 15px;
    }

	&__languageselect {
		position: absolute;
		top: 15px;
		right: 0;
	}

    width: 360px;
    @media (max-width: $windowSmall) {
        width: 90%;
    }

    border-radius: 4px;
    overflow: hidden;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__header {
        background-color: $blue;
        width: 100%;
        padding: 20px 10px;
        box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
        text-align: center;

		& > h1 {
            color: $white;
            text-align: center;
            font-weight: $fontWeightThin;
		}

        & > img {
            margin: auto;
            max-width: 80%;
            height: auto;
        }
    }

    &__form {
        padding: 10px 20px;
        width: 100%;
        background-color: $greyBackground;

		& > div {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		& > span {
			margin-bottom: 5px;
			display: inline-block;
		}

        &__checkbox {
            font-weight: $fontWeightBold;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

		&__error {
			margin-top: 5px;
			margin-bottom: 0;
		}

		&.setpasswordagain {
			padding-top: 0;
		}
    }

    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 20px;

		& > .ant-form-item {
			margin-bottom: 0;
		}

		&__recaptcha {
			margin-bottom: 10px !important;
			display: flex;
			justify-content: center;
			align-items: center;
		}

        &__button {
            width: 100%;
        }

        &__link {
            width: 100%;
            text-decoration: none;
            margin-top: 8px;
            margin-bottom: 2px;
        }

		&__newaccount__button {
			margin-top: 1.5em;
		}
    }
}