.bills {
	display: flex;
	flex-direction: column;
	row-gap: 15px;

    &__table {
        width: 100%;
    }

	&__lockedselect {
		width: 100%;
		max-width: 400px;
		display: flex;
		flex-direction: column;

		&__select {
			margin-top: 10px;
			margin-bottom: 15px;
			width: 100%;
		}

		&__button {
			align-self: flex-end;
		}
	}
}