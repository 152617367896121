.fadein {
	transition-property: opacity;
	transition-duration: 200ms;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&.visible {
		opacity: 1;
	}

	&.hidden {
		opacity: 0;
	}
}