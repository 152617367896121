.exports {
	max-width: 700px;

	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__status, &__format {
		&__select {
			display: flex;
			flex-direction: column;
		}

		&__includefiles {
			margin-left: 25px;
		}
	}

	&__type {
		&__select {
			display: flex;
			flex-direction: column;
		}

		&__filter {
			&__container {
				max-height: 100px;
				margin: 15px 0;
			}

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			row-gap: 5px;
			column-gap: 5px;
			align-items: center;
			transition-property: height;
			transition-duration: 300ms;
			overflow: hidden;

			&.visible {
				height: 60px;
				@media (max-width: 400px) {
					height: 100px
				}
				@media (max-width: 250px) {
					height: 120px
				}
			}
			&.hidden {
				height: 0px;
			}

			& > span {
				display: inline-block;
				width: 100%;
			}

			& > .ant-form-item {
				margin-bottom: 0 !important;
			}

			&__date {
				&__extra {
					display: flex;
					flex-direction: row;
					width: 100%;
					padding: 5px;
					column-gap: 10px;
					align-items: center;

					&__buttons {
						flex: 1;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						column-gap: 5px;
						row-gap: 5px;
						height: 100%;

						& > button {

							& > span {
								display: inline-block;
								width: 100%;
								text-align: left;
							}
						}
					}
				}

				@media (min-width: 751px) {
					&__extra {
						display: flex;
						flex-direction: column;
						width: 135px;
						padding: 5px;
						row-gap: 5px;

						&__buttons {
							width: 100%;
							display: flex;
							flex-direction: column;
							row-gap: 5px;
							height: 100%;

							& > button {
								width: 100%;

								& > span {
									display: inline-block;
									width: 100%;
									text-align: left;
								}
							}
						}
					}
				}

				@media (max-width: 600px) {
					&__extra {
						display: flex;
						flex-direction: column;
						width: 100%;
						padding: 5px;
						row-gap: 5px;

						&__buttons {
							width: 100%;
							display: flex;
							flex-direction: column;
							row-gap: 5px;
							height: 100%;

							& > button {
								width: 100%;

								& > span {
									display: inline-block;
									width: 100%;
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}

	&__submit {
		align-self: flex-end;
		margin-bottom: 0;
	}
}

@media (min-width: 751px) {
	.ant-picker-panel-layout {
		& > div {
			display: flex;
			flex-direction: row-reverse;
		}

		& > div > .ant-picker-footer {
			width: 135px;
			min-width: 135px;
			margin-right: 5px;

			& > .ant-picker-footer-extra {
				width: 135px;
				height: 100%;
			}
		}
	}
}

@media (max-width: 750px) {
	.ant-picker-panel-layout {
		& > div {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

@media (max-width: 600px) {
    .ant-picker-panel-layout {
        width: 288px;
    }

	.ant-picker-panels {
		display: flex;
		flex-direction: column;
	}

    .ant-calendar-range-part {
        width: 100%;
    }
}
