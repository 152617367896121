.routecontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    background-color: $contentBackground;

    &__title {
        padding: 15px 20px 0 15px;
        width: 100%;

        & > h3 {
            margin-bottom: 15px;
        }
    }

    &__divider {
        margin: 0;
    }

    &__content {
        flex-grow: 1;
        padding: 20px 15px;
        width: 100%;

		&.hidden {
			opacity: 0;
		}

		&.visible {
			opacity: 1;
			transition: opacity 500ms ease;
		}
    }
}