.mileages {
    &__content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;

        @media (max-width: $windowMedium) {
            & > div {
                min-width: 100%;
            }
        }
    }

    &__invoices {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;

        &__description {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            width: 100%;
        }

        &__select {
            width: 100%;
        }

        &__link {
            color: $blue;
            cursor: pointer;
        }

        &__save {
            align-self: flex-end;
        }
    }

    &__categories {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;

        &__table {
            width: 100%;

            & tr:hover {
                cursor: pointer;
            }

            & td {
                padding: 5px !important;
            }

            &__edit {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                column-gap: 5px;
                color: $blue;

                & > span {
                    color: $blue;
                }

                &:hover {
                    opacity: 0.88;
                }
            }
        }

        &__new {
            align-self: flex-start;
			margin-top: 10px;
        }
    }

    &__modal {
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 5px;

        &__info {
            margin-top: 15px;
        }

		&__footer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			flex-wrap: wrap;
			row-gap: 8px;
			width: 100%;

			&.delete {
				justify-content: space-between;
			}
		}

		&__edit {
			display: flex;
			flex-direction: column;

			& > .ant-typography {
				margin-bottom: 5px;
			}

			& > .ant-select {
				margin: 5px 0 8px 0;
			}

			& > .ant-spin {
				align-self: center;
				margin: 5px 0;
			}
		}
    }
}