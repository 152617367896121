.accountinformation {
    &__content {
        width: 100%;
		max-width: 1200px;

		display: flex;
		flex-direction: column;
		row-gap: 15px;
    }

	&__information {
		&__button {
			align-self: flex-end;
			margin-top: 15px;
		}

		&__edit {
			& > div {
				margin-bottom: 15px;
			}

			& > span {
				display: block;
			}

			& > span:first-of-type {
				margin-bottom: 15px;
			}

			&__iban, &__formpassword {
				margin-bottom: 3px !important;
			}

			&__accounthelptext textarea {
				margin-top: 3px;
			}
		}
	}

	&__users {
		&__delete {
			display: flex;
			flex-direction: row;
			column-gap: 3px;
			align-items: center;
		}

		&__info {
			margin-top: 15px;
		}
	}

	&__table {
		border-bottom: 1px solid $borderColor;
		width: 100%;

		&__row {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			border-top: 1px solid $borderColor;
			padding: 10px;

			&__item {
				flex: 1;
				min-height: 24px;

				&.userdelete {
					align-self: center;
					flex: 0.5;
				}

				&.formpassword {
					display: flex;
					flex-direction: column;
					row-gap: 5px;
				}

				&__text {
					white-space: pre-line;
				}
			}

			@media (max-width: $windowSmall) {
				flex-direction: column;
				justify-content: flex-start;

				&__item {
					min-height: 0;
				}

				&__item__text.hidden {
					display: none;
				}

				&__item.node {
					display: flex;
					width: 100%;
					align-items: flex-start;

					& > button {
						padding: 0;
						transition-duration: 0ms;
					}
				}
			}
		}
	}
}