.userinformation {
    &__content {
        width: 100%;
		max-width: 1400px;
    }

    &__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;

		& > div {
			flex: none;
			width: calc(33% - 22.5px);
		}

        @media (max-width: $windowMedium) {
            & > div {
                min-width: calc(50% - 15px);
            }
        }

        @media (max-width: $windowSmall) {
            & > div {
                min-width: 100%;
            }
        }

		&__form {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			& > div {
				margin-bottom: 10px;
			}
		}

		&__input {
			width: 100%;
		}

        &__submit {
			flex: 1;
            align-self: flex-end;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-bottom: 0 !important;
        }
    }
}