@import './settings.scss';
@import './components/index.scss';
@import './routes/index.scss';

// Import font from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

.App {
    text-align: center;
    color: $blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

body {
    margin: 0;
    font-family: 'Source Sans Pro',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: $fontNormal;
    color: $gray;
}

h1 {
    font-size: $fontLarge;
    font-weight: $fontWeightNormal;
    line-height: 1.1;
    margin: 0;
}

.link {
    color: $blue;
    cursor: pointer;
}

.ant-btn-sm {
    font-size: 13px !important;
}