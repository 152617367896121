.input {
	display: flex;
	flex: 1;
	flex-direction: column;
	row-gap: 3px;

	&__label {
		display: flex;
		flex-direction: row;
		column-gap: 10px;
		align-items: center;
	}
}