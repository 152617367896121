$blue: #0081C9;
$secondaryBlue: #5BC0F8;
$white: #FFFFFF;
$lightgray: #F6F6F6;
$greyBackground: #EAEAEC;
$gray: #333333;
$borderColor: #DBDBDB;
$darkBackground: #222222;
$contentBackground: #F9F9F9;

$menuSelectedIcon: #222222;
$menuIcon: #555555;
$menuSelectedItem: #333333;
$menuItem: #777777;
$menuBackground: #FFFFFF;
$menuSelectedItemBackground: #F9F9F9;

$stepsBorder: #DDDDDD;
$stepsRed: #dc3545;
$stepsBlue: #0073B7;
$stepsGreen: #00A65A;
$invoiceAccept: #00A65A;
$invoiceAcceptHover: #0AB669;
$invoiceEdit: #FFA500;
$invoiceEditHover: #F8B332;

$fontWeightThin: 300;
$fontWeightNormal: 400;
$fontWeightBold: 700;

$fontNormal: 15px;
$fontLarge: 26px;

$windowSmall: 640px;
$windowMedium: 900px;
$windowLarge: 1200px;