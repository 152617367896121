$topbarHeight: 50px;

.menu {
    &__topbar {
        background-color: $blue;
        width: 100%;
        display: flex;
        flex-direction: row;
		flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        color: $lightgray;

		&__logo {
			width: 220px;
			height: $topbarHeight;
			display: flex;
			justify-content: center;
			align-items: center;

			& > img {
				width: 200px;
				height: auto;
			}
		}

		&__iconbutton {
            height: $topbarHeight;
            width: $topbarHeight;
            display: flex;
            justify-content: center;
            align-items: center;
        }

		&__languageselect {
			display: flex;
			justify-content: flex-end;
			flex: 1;
			padding: 0 15px;

			&__dropdown {
				width: 125px;
			}
		}

        &__openform {
            height: $topbarHeight;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > svg {
                margin-right: 6px;
            }
        }

        &__iconbutton:hover, &__openform:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }

		@media (max-width: $windowSmall) {
			&__openform {
				display: none;
			}

			&__logo {
				width: 150px;
				& > img {
					max-width: calc(100% - 20px);
				}
			}
		}
		@media (max-width: 350px) {
			&__logo {
				width: 100px;
			}
		}
    }

    &__bottomsection {
        display: flex;
        flex-direction: row;
        height: 100%;
		position: relative;
		flex: 1;
    }

    &__sidebar {
        width: 220px;
        flex-shrink: 0;
        background-color: $menuBackground;
        box-shadow: inset -3px 0 8px -4px rgba(0, 0, 0, 0.07);
        overflow: hidden;
		transition-property: width;
		transition-duration: 300ms;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
		height: auto;
		text-align: left !important;

		& > .ant-divider-horizontal {
			margin: 10px 0 !important;
		}

		@media (max-width: calc($windowSmall + 220px)) {
			position: absolute;
			z-index: 2000;
			top: 0;
			bottom: 0;
			left: 0;
        }

        &.closed {
            width: 0px;
        }

		&__userinfo {
			padding: 12px 28px;
			width: 220px;
			border-inline-end: none;

			&__loading {
				&__button {
					width: 100px !important;
				}
			}

			& > .ant-typography {
				margin-bottom: 8px;
			}

			&__accountselector {
				z-index: 2100;
			}

			& > button {
				max-width: 100%;

				& > div {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					max-width: 100%;

					& > div {
						text-overflow: ellipsis;
						overflow: hidden;
						flex: 1;
					}
				}
			}
		}

		&__antd {
			border-inline-end: none !important;
			width: 220px;

			& .ant-menu-item {
				padding-right: 0 !important;
			}
		}
    }

    &__appcontent {
        width: 100%;
    }
}