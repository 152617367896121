.createaccount {
	background-color: $darkBackground;
	width: 100%;
	min-height: 100vh;
	padding-top: 90px;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	&__languageselect {
		position: absolute;
		top: 15px;
		right: 0;
	}

	& > a {
		color: #fff;
		margin: 15px 0;
		display: inline-block;
		text-align: left;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__logo {
		background-color: $blue;
		width: 100%;
		padding: 20px 10px;
		box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
		text-align: center;
		max-width: 600px;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;

		& > img {
			margin: auto;
			max-width: 300px;
			height: auto;
		}
	}

	&__form__info {
		font-size: 18px;
		line-height: 1.5;
	}

	&__steps {
		padding: 10px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		& .ant-steps-item-title {
			font-size: $fontNormal;
		}

		@media (max-width: $windowSmall) {
			max-width: 400px;
		}
	}

	&__container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__content {
		width: 100%;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 25px;
		padding-bottom: 15px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-top: 0;
	}

	&__carousel {
		// transition: all 100ms ease;

		&__provider {
			width: 100%;
		}
	}


	&__form {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: $windowSmall) {
			padding: 0 30px;
		}

		&__info {
			margin-bottom: 20px;
		}

		&__item {
			margin-bottom: 24px;
			display: flex;
			flex-direction: column;
			row-gap: 5px;

			& .ant-form-item {
				margin-bottom: 0 !important;
			}
		}

		&__label {
			margin-bottom: 3px;
			display: inline-block;
		}

		&__select {
			width: 100%;
			margin-bottom: 24px;

			& > label {
				width: 50%;
				text-align: center;
			}
		}

		&__useritem__info {
			overflow: hidden;
			transition-property: all;
			transition-duration: 300ms;

			&.visible {
				max-height: 200px;
				margin-bottom: 15px;
			}

			&.hidden {
				max-height: 0;
				margin-bottom: 0;
			}
		}

		&__einvoiceitem, &__useritem {
			transition-property: all;
			transition-duration: 300ms;

			&.visible {
				height: 57px; // Height of one Form.Item
				margin-bottom: 24px; // Margin of one Form.Item
				overflow: visible;
			}
			&.hidden {
				overflow: hidden;
				height: 0;
				margin-bottom: 0;
			}
			&.login {
				width: 100%;
			}
		}

		&__loggedin {
			transition-property: all;
			transition-duration: 300ms;
			overflow: hidden;

			&.visible {
				height: 100px;
				margin-bottom: 15px;
			}
			&.hidden {
				height: 0;
				margin-bottom: 0;
			}
		}

		&__invoicing {
			&__helptext {
				margin-bottom: 24px;
				display: inline-block;
			}
		}

		&__buttons {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			& .ant-form-item {
				margin-bottom: 0 !important;
			}
		}

		&__submit {
			align-self: flex-end;
		}
	}

	&__summary {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: $windowSmall) {
			padding: 0 calc((100% - 300px) / 2);
		}

		& > h5 {
			margin-top: 20px;
		}

		&__einvoice {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			& > span {
				display: inline-block;
				width: 50%;
			}
		}

		&__submit, &__recaptcha, &__terms {
			margin-top: 15px;
		}
	}

	&__confirmation {
		width: 100%;
	}
}