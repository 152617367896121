.lockaccountingperiod {
	&__info {
		margin-bottom: 15px;
	}

	&__card {
		max-width: 500px;
		width: 100%;
	}

	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;

		& .ant-form-item {
			margin-bottom: 12px;
		}

		& > input, & .ant-picker {
			width: 100% !important;
		}

		& button {
			align-self: flex-end;
			margin-top: 5px;
		}
	}

	&__selectbills {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 5px 0;
		padding: 10px;
		border: 1px solid $borderColor;
		border-radius: 5px;
		width: 200px;
		align-self: center;
		color: $gray;
		cursor: pointer;
		text-align: center;

		&:hover {
			opacity: 0.7;
		}

		& > span {
			color: $gray;
			margin-bottom: 5px;
		}

		&__modal {
			&__pickertools {
				display: flex;
				flex-direction: row;
				column-gap: 3px;
				margin-bottom: 5px;

				& > span {
					display: inline-block;
					cursor: pointer;
					color: $secondaryBlue;

					&:hover {
						opacity: 0.8;
					}
				}
			}

			& select {
				width: 100%;
				min-height: 500px;
			}
		}

		&__numofselected {
			display: inline;
			align-self: center;
			margin: 5px 0;
		}
	}
}