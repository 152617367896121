.viewbill {
	display: flex;
	flex: 1;
	flex-direction: column;
	row-gap: 15px;

	&__toprow {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&__back {
		display: flex;
		flex-direction: row;
		column-gap: 5px;

		& > button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			column-gap: 5px;
		}
	}

	&__steps {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		row-gap: 20px;
		border-left: 3px solid $stepsBorder;
		padding-left: 30px;
		margin-left: 10px;

		&__filemodal {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			width: 100%;

			& > img {
				width: 100%;
				height: auto;
				margin-top: 20px;
				margin-bottom: 10px;
			}

			& > div {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				row-gap: 5px;
				flex-direction: row;
				column-gap: 8px;

				& > button {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					column-gap: 5px;
				}
			}

			&__nextprev {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				margin-bottom: 3px;
			}
		}

		&__card {
			width: 100%;
			position: relative;

			&__icon {
				$iconDimensions: 30px;

				display: flex;
				justify-content: center;
				z-index: 1000;
				align-items: center;
				position: absolute;
				top: -10px;
				left: -46.5px;
				height: $iconDimensions;
				width: $iconDimensions;
				border-radius: calc($iconDimensions / 2);

				&__red {
					background-color: $stepsRed;
				}
				&__blue {
					background-color: $stepsBlue;
				}
				&__green {
					background-color: $stepsGreen;
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				row-gap: 10px;
				width: 100%;

				&__row {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					column-gap: 5px;
					margin-bottom: 6px;

					& > span {
						width: 180px;

						@media (max-width: $windowSmall) {
							width: 100%;
						}
					}

					& > div {
						display: flex;
						flex: 1;
						flex-direction: column;
						row-gap: 6px;
					}

					&__nolabel {
						margin-bottom: 8px;
					}
				}

				&__editfiles {
					&__modal {
						display: flex;
						flex-direction: column;
						align-items: flex-end;

						& > img {
							width: 100%;
							height: auto;
							margin-top: 20px;
							margin-bottom: 10px;
						}

						& > div {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: space-evenly;
							column-gap: 8px;
							row-gap: 8px;

							& > button {
								display: flex;
								flex-direction: row;
								align-items: center;
								column-gap: 5px;
							}
						}

						&__loadingindicator {
							position: absolute;
							z-index: 1000;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background-color: rgba(255, 255, 255, 0.4);
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 8px;
						}

					}

					&__removed {
						& > .ant-upload-icon > .anticon, & > a, & > .filedeleted {
							color: red !important;
						}
						& > a {
							text-decoration: line-through;
						}
						& .ant-upload-list-item-actions {
							display: none !important;
						}

						& > .restorefile {
							display: flex;
							flex-direction: row;
							column-gap: 5px;
							align-items: center;
						}
					}
				}
			}

			&__actions {
				width: 100%;

				&__buttons {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					row-gap: 5px;
					column-gap: 5px;
					margin-bottom: 15px;

					@media (max-width: calc($windowSmall + 100px)) {
						& > button, & > div.ant-form-item {
							flex: calc(50% - 2.5px);

							& button {
								width: 100%;
							}
						}
					}

					& > .button__edit {
						background-color: $invoiceEdit;
					}
					& > .button__edit:hover {
						background-color: $invoiceEditHover;
					}
					& > .button__accept {
						background-color: $invoiceAccept;
					}
					& > .button__accept:hover {
						background-color: $invoiceAcceptHover;
					}
					& > .button__acceptandreturntolist {
						border-color: $invoiceAccept;
						color: $invoiceAccept !important;
					}
					& > .button__acceptandreturntolist:hover {
						border-color: $invoiceAcceptHover;
						color: $invoiceAcceptHover !important;
						opacity: 0.8;
					}
				}

				&__sendback {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					row-gap: 10px;
					transition-property: all;
					transition-duration: 1000ms;
					overflow: hidden;

					&.visible {
						margin-bottom: 15px;
						max-height: 300px;
					}

					&.hidden {
						max-height: 0px;
						margin-bottom: 0;
					}
				}

				&__info {
					display: flex;
					flex-direction: column;
					row-gap: 10px;

					&__row {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						flex-wrap: wrap;

						& > span:first-of-type {
							display: inline-block;
							width: 150px;
						}

						& > span:last-of-type {
							display: inline-block;
							flex: 1;
						}
					}
				}
			}
		}

		&__formitem {
			margin-bottom: 0;
			width: 100%;

			& .ant-input-group-wrapper, .ant-select {
				max-width: 200px;
			}

			& input {
				width: 100%;
				max-width: 200px;
			}

			& textarea {
				width: 100%;
				max-width: 385px;
			}

			&.fileupload {
				width: 100%;
				max-width: 385px;
				margin-top: 15px;

				& .ant-btn {
					opacity: 1 !important;
				}
			}

			&.button {
				width: auto;
				margin-bottom: 0;
			}
		}
	}

	&__paymentinformation {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-start;
		flex-wrap: wrap;
		column-gap: 25px;
		row-gap: 10px;

		&__sumnegativeinfo {
			width: 100%;
			display: block;
			margin-bottom: 10px;
		}

		&__row {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			& > span:first-of-type {
				width: 150px;
			}

			@media (max-width: $windowSmall) {
				& > span {
					width: 100%;
				}
			}

			&.barcode {
				margin-top: 10px;
			}
		}

		&__markaspaid {
			width: 100%;
		}
	}
}